<template>
  <div class="container my-5" style="max-width: 1400px">
    <h4 class="text-center">ประกาศ</h4>
    <div class="row mt-5">
      <div class="col-12 announcement">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col" style="width: 10%">ลำดับ</th>
              <th scope="col">ชื่อเรื่อง</th>
              <th scope="col" style="width: 15%; text-align: center">
                วันที่ลงประกาศ
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in announcement" :key="i">
              <td style="width: 10%">{{ item.id }}</td>
              <td>
                <span
                  class="badge badge-secondary"
                  style="background-color: red; color: #ffffff"
                  v-if="item.status == 'ใหม่'"
                  >New !</span
                >
                {{ item.topic }}
              </td>
              <td style="width: 15%; text-align: center">
                {{ item.create_thai_date | moment("DD/MM/YYYY") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="user.role !== 2" class="mt-5">
      <h4 class="text-center">ผลการบันทึกข้อมูล</h4>
      <div class="card">
        <div class="card-header bg-grey-1-theme">
          <div>
            กรุณาระบุเงื่อนไขเพื่อทำการค้นหา
            (สามารถเลือกกรอกเพียงอย่างใดอย่างหนึ่ง)
          </div>
        </div>
        <div class="card-body px-3 py-4">
          <div class="row">
            <div class="col-6">
              <label>ชื่อ</label>
              <input v-model="firstName" class="form-control" type="text" />
            </div>
            <div class="col-6">
              <label>นามสกุล</label>
              <input v-model="lastName" class="form-control" type="text" />
            </div>
            <div class="col-3 mt-3">
              <label>สถานะบุคคล</label>
              <b-form-select
                class="form-control"
                @change="setIdentityStatus($event)"
                v-model="status"
              >
                <b-form-select-option value="" disabled
                  >เลือกสถานะบุคคล</b-form-select-option
                >

                <b-form-select-option
                  v-for="(item, i) in personStatus"
                  :key="i"
                  :value="item.name"
                  >{{ item.name }}</b-form-select-option
                >
              </b-form-select>
              <!-- <input v-model="status" class="form-control" type="text" /> -->
            </div>
            <div class="col-3 mt-3">
              <label>{{ statusLabel }}</label>
              <input v-model="idNumber" class="form-control" type="text" />
            </div>
            <div class="col-6 mt-3">
              <label>รหัสผู้ป่วย (HN)</label>
              <input v-model="patientCode" class="form-control" type="text" />
            </div>
          </div>
        </div>
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            v-model="dateSelected"
            :aria-describedby="ariaDescribedby"
          >
            <div class="row violence-radio px-3">
              <div class="col-6 py-4 bg-grey-2-theme">
                <label>วันที่เกิดเหตุ</label>
                <div class="row align-items-center">
                  <div class="col-1">
                    <b-form-radio value="event"></b-form-radio>
                  </div>
                  <div class="col-5">
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          rounded
                          class="mt-2"
                          dense
                          v-model="computedStartEventDateFormatted"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date.startEventDate"
                        @input="menu = false"
                        locale="th-th"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                  <div class="col-1">-</div>
                  <div class="col-5">
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          rounded
                          class="mt-2"
                          dense
                          v-model="computedEndEventDateFormatted"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date.endEventDate"
                        @input="menu2 = false"
                        locale="th-th"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </div>
              </div>
              <div class="col-6 py-4 bg-grey-2-theme">
                <label>วันที่มาโรงพยาบาล</label>
                <div class="row align-items-center">
                  <div class="col-1">
                    <b-form-radio value="hospital"></b-form-radio>
                  </div>
                  <div class="col-5">
                    <v-menu
                      v-model="menu3"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          rounded
                          class="mt-2"
                          dense
                          v-model="computedStartHospitalDateFormatted"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date.startHospitalDate"
                        @input="menu3 = false"
                        locale="th-th"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                  <div class="col-1">-</div>
                  <div class="col-5">
                    <v-menu
                      v-model="menu4"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          rounded
                          class="mt-2"
                          dense
                          v-model="computedEndHospitalDateFormatted"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date.endHospitalDate"
                        @input="menu4 = false"
                        locale="th-th"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </div>
              </div>
            </div>
          </b-form-radio-group>
        </b-form-group>
        <div class="card-body">
          <div class="row align-items-end">
            <div class="col-6">
              <label>โรงพยาบาล</label>
              <input v-model="hospital" class="form-control" type="text" />
            </div>
            <div class="col-6 mt-5">
              <button @click="search" class="btn btn-next-outline">
                ค้นหา
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="user.role !== 2" class="row justify-content-end my-5">
      <div class="col-3">
        <button
          v-if="!isAdmin"
          @click="goto('violenec')"
          class="btn btn-block bg-purple-2-theme text-white round-radius"
        >
          บันทึกผู้ที่ถูกกระทำรุนแรง
        </button>
      </div>
      <div class="col-3">
        <button
          v-if="!isAdmin"
          @click="goto('pregnant')"
          class="btn btn-block bg-green-1-theme text-white round-radius"
        >
          บันทึกผู้ที่ตั้งครรภ์ไม่พึงประสงค์
        </button>
      </div>
      <div class="col-2">
        <button @click="show" class="btn btn-block btn-next-outline">
          ส่งออกข้อมูล
        </button>
      </div>
    </div>
    <div v-if="user.role !== 2" class="row my-5">
      <div class="col-12">
        <table class="table table-striped">
          <thead class="thead-dark">
            <tr>
              <th scope="col">ลำดับ</th>
              <th scope="col">รหัสโรงพยาบาล</th>
              <th style="width: 150px" scope="col">โรงพยาบาล</th>
              <th scope="col">รหัสผู้ป่วย</th>
              <th scope="col">วันที่มาโรงพยาบาล</th>
              <th style="width: 200px" scope="col">ชื่อ - สกุล</th>
              <th scope="col">วันที่เกิดเหตุ</th>
              <th scope="col">อายุ</th>
              <th scope="col">เพศ</th>
              <th scope="col">สถานะ</th>
              <th scope="col">refer</th>
              <th style="width: 250px" scope="col">ชื่อผู้บันทึก</th>
              <th scope="col">จัดการกระทำรุนแรง</th>
              <th scope="col">จัดการตั้งครรภ์ไม่พึงประสงค์</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in formList" :key="item.id">
              <td>{{ parseInt(index)  +1  }}</td>
              <td>
                {{
                  item.violence1
                    ? item.violence1.service_code
                    : item.pregnant1
                    ? item.pregnant1.service_code
                    : ""
                }}
              </td>
              <td>
                {{
                  item.violence1
                    ? item.violence1.service_name
                    : item.pregnant1
                    ? item.pregnant1.service_name
                    : ""
                }}
              </td>
              <td>
                {{
                  item.violence1
                    ? item.violence1.oscc_no
                    : item.pregnant1
                    ? item.pregnant1.oscc_no
                    : ""
                }}
              </td>
              <td>
                {{
                  item.violence2
                    ? changeTimeFormat(item.violence2.hospital_date)
                    : changeTimeFormatPregnant(
                        item.pregnant1 ? item.pregnant1.created_at : ""
                      )
                }}
              </td>
              <td>
                {{
                  item.violence1
                    ? item.violence1.first_name
                    : item.pregnant1
                    ? item.pregnant1.first_name
                    : ""
                }}
                {{
                  item.violence1
                    ? item.violence1.last_name
                    : item.pregnant1
                    ? item.pregnant1.last_name
                    : ""
                }}
              </td>
              <td>
                {{
                  item.violence2
                    ? changeTimeFormat(item.violence2.event_date)
                    : ""
                }}
              </td>
              <td>
                {{
                  item.violence1
                    ? item.violence1.age
                    : item.pregnant1
                    ? item.pregnant1.age
                    : ""
                }}
              </td>
              <td>
                {{
                  item.violence1
                    ? item.violence1.sex
                    : item.pregnant1
                    ? item.pregnant1.sex
                    : ""
                }}
              </td>
              <td>
                <template
                  v-if="
                    item.status == 'อนุมัติ refer' && item.status_refer == false
                  "
                >
                  <button
                    :disabled="item.canAccept"
                    class="btn btn-danger"
                    @click="accept_refer(item)"
                  >
                    {{ item.status }}
                  </button>
                </template>
                <template
                  v-else-if="
                    item.status == 'อนุมัติ refer' && item.status_refer == true
                  "
                >
                  <button class="btn btn-outline-success" disabled>
                    {{ item.status }}
                  </button>
                </template>
                <template v-else>
                  {{ item.status }}
                </template>
              </td>
              <td class="text-center">
                <template
                  v-if="
                    item.refers &&
                    (item.status == 'อนุมัติ' || item.status == 'อนุมัติ refer')
                  "
                >
                  {{ item.refers.from }}<br />
                  <i class="arrow down"></i><br />
                  {{ item.refers.to }}
                </template>
              </td>
              <td>{{ item.user.full_name }}</td>
              <td class="text-center">
                <button
                  v-if="item.violence1"
                  @click="goto('violenec', item.violence_form_one_id, item.id)"
                  class="btn"
                >
                  <i v-if="isAdmin" class="bi bi-eye text-violenec"></i>
                  <img v-else :src="editViolence" />
                </button>
                <button
                  v-else
                  @click="goto('violenec', null, item.id)"
                  class="btn"
                >
                  <i v-if="!isAdmin" class="bi bi-plus-circle"></i>
                </button>
              </td>
              <td class="text-center">
                <button
                  v-if="item.pregnant1"
                  @click="goto('pregnant', null, item.id)"
                  class="btn"
                >
                  <i v-if="isAdmin" class="bi bi-eye text-pregnant"></i>
                  <img v-else :src="editPregnant" />
                </button>
                <button
                  v-if="
                    item.violence1 &&
                    item.violence1.sex === 'หญิง' &&
                    !item.pregnant1
                  "
                  @click="goto('pregnant', null, item.id)"
                  class="btn"
                >
                  <i v-if="!isAdmin" class="bi bi-plus-circle"></i>
                </button>
              </td>
              <td>
                <button
                  v-if="isAdmin || item.status == 'ร่างแบบบันทึก'"
                  @click="deleteItem(item.id)"
                  class="btn btn-danger"
                >
                  ลบ
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex align-items-center justify-content-around my-5">
          <div>
            {{
              `${paginate.from ? paginate.from : 0}-${
                paginate.to ? paginate.to : 0
              } of ${paginate.total} items`
            }}
          </div>
          <v-pagination
            @previous="goToPage"
            @next="goToPage"
            @input="goToPage"
            v-model="paginate.currentPage"
            :length="paginate.lastPage"
          ></v-pagination>
          <div>
            <select
              v-model="row"
              @change="selectRow"
              class="row-option py-2 px-3"
            >
              <option
                v-for="(item, index) in rowOption"
                :key="index"
                :value="item"
              >
                {{ item }} / page
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/th";
import Service from "../services";
import editViolence from "../assets/icon/editViolence.png";
import editPregnant from "../assets/icon/editPregnant.png";
import moment from "moment";
import { mapState } from "vuex";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      statusLabel: "เลขบัตรประจำตัวประชาชน",
      personStatus: [
        { name: "บัตรประชาชน", isShow: true },
        { name: "บัตรต่างชาติ หรือ พาสปอร์ต", isShow: true },
        { name: "ไม่มี", isShow: false },
      ],
      dateSelected: "event",
      row: 10,
      menu:false,
      menu1:false,
      menu2:false,
      menu3:false,
      menu4:false,
      rowOption: [5, 10, 20, 50],
      firstName: "",
      lastName: "",
      status: "",
      idNumber: "",
      date: {
        startEventDate: "",
        endEventDate: "",
        startHospitalDate: "",
        endHospitalDate: "",
      },
      hospital: "",
      patientCode: "",
      editViolence: editViolence,
      editPregnant: editPregnant,
      announcement: [],
      moment: moment.locale("th"),
    };
  },
  computed: {
    computedStartEventDateFormatted() {
      if (!this.date.startEventDate) return null;
      const [year, month, day] = this.date.startEventDate.split("-");
      return `${day}/${month}/${parseInt(year) + 543}`;
    },
    computedEndEventDateFormatted() {
      if (!this.date.endEventDate) return null;
      const [year, month, day] = this.date.endEventDate.split("-");
      return `${day}/${month}/${parseInt(year) + 543}`;
    },
    computedStartHospitalDateFormatted() {
      if (!this.date.startHospitalDate) return null;
      const [year, month, day] = this.date.startHospitalDate.split("-");
      return `${day}/${month}/${parseInt(year) + 543}`;
    },
    computedEndHospitalDateFormatted() {
      if (!this.date.endHospitalDate) return null;
      const [year, month, day] = this.date.endHospitalDate.split("-");
      return `${day}/${month}/${parseInt(year) + 543}`;
    },
    ...mapState({
      formList: (state) => {
        return state.Form.formList;
      },
      paginate: (state) => {
        return state.Form.paginateFormList;
      },
      hosptalCode: (state) => {
        if (state.Auth.user) {
          if (state.Auth.user.role == 1) {
            return "";
          } else {
            return state.Auth.user.agency_code;
          }
        } else {
          return "";
        }
      },
      user: (state) => {
        return state.Auth.user;
      },
      isAdmin: (state) => {
        const isAdmin = state.Auth.user ? state.Auth.user.role == 1 : false;
        return isAdmin;
      },
    }),
  },
  async created() {
    if (this.user) {
      this.showLoading();
      Service.Common.getData().then((res) => {
        this.announcement = res.data;
      });
      await this.$store.dispatch("Form/list", {
        page: 1,
        hospital_code: this.hosptalCode,
        row: this.row,
      });
      this.hideLoading();
    } else {
      this.$router.push({
        path: "/",
      });
    }
  },
  methods: {
    accept_refer(data) {
      Swal.fire({
        title: "ยืนยันรับ refer",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: `ยกเลิก`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("Form/accept_refer", data).then(() => {
            this.$store.dispatch("Form/list", {
              page: 1,
              hospital_code: this.hosptalCode,
              row: this.row,
            });
          });
        }
      });
    },
    initDate(prop) {
      if (!this.date[prop]) {
        this.convertDate(prop);
      }
    },
    show() {
      Swal.fire({
        title: "กำลังแสดงข้อมูล",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: async () => {
          Swal.showLoading();
          await this.$store
            .dispatch("Form/exportFormResult", {
              oscc_no: this.patientCode,
              first_name: this.firstName,
              last_name: this.lastName,
              id_number: this.idNumber,
              hospital: this.hospital,
              hospital_code: this.hosptalCode,
              start_event_date:
                this.dateSelected === "event"
                  ? this.formatDate(this.date.startEventDate)
                  : "", // moment(this.date.startEventDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
              end_event_date:
                this.dateSelected === "event"
                  ? this.formatDate(this.date.endEventDate)
                  : "", //moment(this.date.endEventDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
              start_hospital_date:
                this.dateSelected === "hospital"
                  ? this.formatDate(this.date.startHospitalDate)
                  : "", //moment(this.date.startHospitalDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
              end_hospital_date:
                this.dateSelected === "hospital"
                  ? this.formatDate(this.date.endHospitalDate)
                  : "", //moment(this.date.endHospitalDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
              status: this.status,
            })
            .then((response) => {
              window.open(response);
              Swal.close();
            })
            .catch(() => {
              Swal.close();
            });
        },
      });
    },
    setIdentityStatus(model) {
      switch (model) {
        case "บัตรประชาชน":
          this.statusLabel = "เลขบัตรประจำตัวประชาชน";
          break;
        case "บัตรต่างชาติ หรือ พาสปอร์ต":
          this.statusLabel = "เลขบัตรต่างชาติ หรือ พาสปอร์ต";
          break;
        case "ไม่มี":
          this.statusLabel = "เลขประจำตัว";
          break;
      }
    },
    convertDate(name) {
      let christianYear = moment().format("YYYY");
      let buddhishYear = (parseInt(christianYear) + 543).toString();
      let thaiDate = moment()
        .format(
          "DD-MM-YYYY"
            .replace("YYYY", buddhishYear)
            .replace("YY", buddhishYear.substring(2, 4))
        )
        .replace(christianYear, buddhishYear);
      this.date[name] = thaiDate;
    },
    async search() {
      this.showLoading();
      await this.$store.dispatch("Form/list", {
        page: 1,
        row: this.row,
        oscc_no: this.patientCode,
        first_name: this.firstName,
        last_name: this.lastName,
        id_number: this.idNumber,
        hospital: this.hospital,
        hospital_code: this.hosptalCode,
        start_event_date:
          this.dateSelected === "event"
            ? this.formatDate(this.date.startEventDate)
            : "", // moment(this.date.startEventDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
        end_event_date:
          this.dateSelected === "event"
            ? this.formatDate(this.date.endEventDate)
            : "", //moment(this.date.endEventDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
        start_hospital_date:
          this.dateSelected === "hospital"
            ? this.formatDate(this.date.startHospitalDate)
            : "", //moment(this.date.startHospitalDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
        end_hospital_date:
          this.dateSelected === "hospital"
            ? this.formatDate(this.date.endHospitalDate)
            : "", //moment(this.date.endHospitalDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
        status: this.status,
      });
      this.hideLoading();
    },
    formatDate(date) {
      return date
        ? moment(date, "YYYY-MM-DD").add(543, "years").format("YYYY-MM-DD")
        : "";
    },
    showLoading() {
      this.$loading.show({
        background: this.$swal.fire({
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        }),
        animation: true,
      });
    },
    hideLoading() {
      this.$loading.hide();
      this.$swal.hideLoading();
    },
    changeTimeFormatPregnant(timestamp) {
      if (timestamp) {
        let christianYear = moment(timestamp).format("YYYY");
        let buddhishYear = (parseInt(christianYear) + 543).toString();
        let thaiDate = moment(timestamp)
          .format(
            "DD/MM/YYYY"
              .replace("YYYY", buddhishYear)
              .replace("YY", buddhishYear.substring(2, 4))
          )
          .replace(christianYear, buddhishYear);

        return thaiDate;
      } else {
        return "";
      }
    },
    changeTimeFormat(time) {
      if (time) {
        return moment(time, "YYYY-MM-DD").format("DD/MM/YYYY");
      } else {
        return "";
      }
    },
    async selectRow() {
      this.showLoading();
      await this.$store.dispatch("Form/list", {
        page: 1,
        hospital_code: this.hosptalCode,
        //hospital:'',
        // patient_code:'',
        // start_event_date:'',
        // end_event_date:'',
        // start_hospital_date:'',
        // end_hospital_date:'',
        row: this.row,
      });
      this.hideLoading();
    },
    async goToPage(event) {
      this.showLoading();
      const page = event ? event : this.paginate.currentPage;
      await this.$store.dispatch("Form/list", {
        page: page,
        row: this.row,
        oscc_no: this.patientCode,
        first_name: this.firstName,
        last_name: this.lastName,
        id_number: this.idNumber,
        hospital: this.hospital,
        hospital_code: this.hosptalCode,
        start_event_date:
            this.dateSelected === "event"
                ? this.formatDate(this.date.startEventDate)
                : "", // moment(this.date.startEventDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
        end_event_date:
            this.dateSelected === "event"
                ? this.formatDate(this.date.endEventDate)
                : "", //moment(this.date.endEventDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
        start_hospital_date:
            this.dateSelected === "hospital"
                ? this.formatDate(this.date.startHospitalDate)
                : "", //moment(this.date.startHospitalDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
        end_hospital_date:
            this.dateSelected === "hospital"
                ? this.formatDate(this.date.endHospitalDate)
                : "", //moment(this.date.endHospitalDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
        status: this.status,
      });

      this.hideLoading();
    },
    deleteItem(id) {
      Swal.fire({
        title: "ลบผลการบันทึกข้อมูล",
        icon: "error",
        showCancelButton: true,
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ตกลง",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.showLoading();
          await this.$store.dispatch("Form/delete", id);
          await this.$store.dispatch("Form/list", {
            page: 1,
            hospital_code: this.hosptalCode,
            row: this.row,
          });
          this.hideLoading();
          Swal.fire("สำเร็จ", "ลบผลการบันทึกข้อมูลสำเร็จ", "success");
        }
      });
    },
    goto(path, id, form_id) {
      this.$router.push({
        path: path,
        query: { id: id, form: form_id },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.announcement table {
  border-radius: 10px;
  overflow: hidden;

  tbody {
    display: block;
    height: 150px;
    overflow: auto;

    tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }
  }

  thead {
    display: table;
    width: 100%;
  }
}

.announcement tbody tr:nth-child(odd) {
  background: #ffffff !important;
}

.announcement tbody tr:nth-child(even):not(:first-child) {
  background: linear-gradient(0deg, #f9faff, #f9faff), #ffffff !important;
}

.announcement thead tr {
  background-color: #dee5fb;
  color: #505b80;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
</style>
